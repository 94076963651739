import React from 'react';
import NumberFormat from 'react-number-format';
import { Grid, InputAdornment } from '@material-ui/core';

import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';

import AutoComplete from 'components/atoms/AutoComplete';
import { TextMaskCustom, CEPMaskCustom } from 'components/MaskTextInput';

import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const MaritalForm = ({
  formData,
  onChange,
  statesOptions,
  civilStatusOptions,
  employmentsOptions,
  enableCopyButton,
  disableAllInputs,
  citiesOptions,
  handleKeyPress,
  getAddresInfosByZipCode,
}) => {
  return (
    <Grid container spacing={1}>
      {/* ROW */}
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerName"
          label="Nome"
          value={formData.maritalPartnerName}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          id="maritalPartnerBirthDate"
          name="maritalPartnerBirthDate"
          type="date"
          label="Data de nascimento"
          value={formData.maritalPartnerBirthDate}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          name="maritalPartnerCpf"
          label="CPF"
          value={formData.maritalPartnerCpf}
          onChange={onChange}
          disabled={disableAllInputs}
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={3}>
        <TextInput
          name="maritalPartnerRgNumber"
          label="RG"
          value={formData.maritalPartnerRgNumber}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          id="maritalPartnerRgIssuedAt"
          name="maritalPartnerRgIssuedAt"
          type="date"
          label="Data emissão do RG"
          value={formData.maritalPartnerRgIssuedAt}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          name="maritalPartnerRgIssuedState"
          label="Orgão emissor"
          value={formData.maritalPartnerRgIssuedState}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <AutoComplete
          name="maritalRgIssuedState"
          label="UF do orgão emissor*"
          options={statesOptions}
          value={
            formData?.['maritalRgIssuedState']
              ? {
                  id: formData['maritalRgIssuedState'],
                  value: formData['maritalRgIssuedState'],
                  label: statesOptions.find(
                    e => e.value === formData['maritalRgIssuedState']
                  )?.label,
                  name: statesOptions.find(
                    e => e.value === formData['maritalRgIssuedState']
                  )?.label,
                }
              : null
          }
          onChange={e =>
            onChange({
              target: { value: e?.value, name: 'maritalRgIssuedState' },
            })
          }
          disabled={disableAllInputs}
          minHeight="58px"
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerFatherName"
          label="Nome do pai"
          value={formData.maritalPartnerFatherName}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerMotherName"
          label="Nome da mãe"
          value={formData.maritalPartnerMotherName}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={4}>
        <TextInput
          name="maritalPartnerNationality"
          label="Nacionalidade"
          value={formData.maritalPartnerNationality}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <AutoComplete
          name="maritalPartnerCivilStatus"
          label="Estado civil*"
          value={
            formData?.['maritalPartnerCivilStatus']
              ? {
                  id: formData['maritalPartnerCivilStatus'],
                  value: formData['maritalPartnerCivilStatus'],
                  label: civilStatusOptions.find(
                    e => e.value === formData['maritalPartnerCivilStatus']
                  )?.label,
                  name: civilStatusOptions.find(
                    e => e.value === formData['maritalPartnerCivilStatus']
                  )?.label,
                }
              : null
          }
          onChange={e =>
            onChange({
              target: { value: e?.value, name: 'maritalPartnerCivilStatus' },
            })
          }
          options={civilStatusOptions}
          disabled={disableAllInputs}
          minHeight="58px"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          id="maritalPartnerPhone"
          name="maritalPartnerPhone"
          label="Telefone/Celular"
          value={formData['maritalPartnerPhone']}
          disabled={disableAllInputs}
          InputProps={{
            inputComponent: TextMaskCustom,
            onChange: onChange,
            endAdornment: enableCopyButton ? (
              <RoundedButton
                onClick={() => contentCopyByNameInput('maritalPartnerPhone')}
              >
                <ContentCopyIcon />
              </RoundedButton>
            ) : null,
          }}
          required
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerCompanyName"
          label="Empresa aonde trabalha"
          value={formData.maritalPartnerCompanyName}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerJobTitle"
          label="Cargo"
          value={formData.maritalPartnerJobTitle}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={4}>
        <NumberFormat
          label={'Renda mensal*'}
          value={formData['maritalPartnerMonthlyIncome']}
          onValueChange={({ floatValue }): void => {
            onChange({
              target: {
                name: 'maritalPartnerMonthlyIncome',
                value: floatValue,
              },
            });
          }}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          placeholder="0"
          prefix="R$ "
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={TextInput}
          disabled={disableAllInputs}
        />
      </Grid>
      <Grid item xs={4}>
        <AutoComplete
          name="maritalPartnerEmploymentId"
          label="Tipo de ocupação*"
          options={employmentsOptions}
          value={
            formData?.['maritalPartnerEmploymentId']
              ? {
                  id: formData['maritalPartnerEmploymentId'],
                  value: formData['maritalPartnerEmploymentId'],
                  label: employmentsOptions.find(
                    e => e.value === formData['maritalPartnerEmploymentId']
                  )?.label,
                  name: employmentsOptions.find(
                    e => e.value === formData['maritalPartnerEmploymentId']
                  )?.label,
                }
              : null
          }
          onChange={e =>
            onChange({
              target: { value: e?.value, name: 'maritalPartnerEmploymentId' },
            })
          }
          disabled={disableAllInputs}
          minHeight="58px"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          name="maritalPartnerEmploymentStartDate"
          label="Data de Inicio na empresa"
          type="date"
          value={formData.maritalPartnerEmploymentStartDate}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={2}>
        <TextInput
          id="maritalPartnerZipcode"
          name="maritalPartnerZipcode"
          label="CEP"
          value={formData['maritalPartnerZipcode']}
          onKeyPress={handleKeyPress}
          onChange={onChange}
          disabled={disableAllInputs}
          InputProps={{
            inputComponent: CEPMaskCustom,
            endAdornment: (
              <InputAdornment position="end">
                <SearchButton onClick={getAddresInfosByZipCode} />
              </InputAdornment>
            ),
          }}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="maritalPartnerAddress"
          label="Rua"
          value={formData.maritalPartnerAddress}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          id="maritalPartnerAddressNumber"
          name="maritalPartnerAddressNumber"
          label="Número"
          value={formData['maritalPartnerAddressNumber']}
          onChange={onChange}
          disabled={disableAllInputs}
          InputProps={{
            endAdornment: enableCopyButton ? (
              <RoundedButton
                onClick={() =>
                  contentCopyByNameInput('maritalPartnerAddressNumber')
                }
              >
                <ContentCopyIcon />
              </RoundedButton>
            ) : null,
          }}
          required
        />
      </Grid>

      {/* ROW */}
      <Grid item xs={4}>
        <TextInput
          name="maritalPartnerNeighborhood"
          label="Bairro"
          value={formData.maritalPartnerNeighborhood}
          onChange={onChange}
          disabled={disableAllInputs}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <AutoComplete
          name="maritalPartnerCityStateId"
          label="Estado*"
          options={statesOptions}
          value={
            formData?.['maritalPartnerCityStateId']
              ? {
                  id: formData['maritalPartnerCityStateId'],
                  value: formData['maritalPartnerCityStateId'],
                  label: statesOptions.find(
                    e => e.value === formData['maritalPartnerCityStateId']
                  )?.label,
                  name: statesOptions.find(
                    e => e.value === formData['maritalPartnerCityStateId']
                  )?.label,
                }
              : null
          }
          onChange={e =>
            onChange({
              target: { value: e?.value, name: 'maritalPartnerCityStateId' },
            })
          }
          disabled={disableAllInputs}
          minHeight="58px"
        />
      </Grid>
      <Grid item xs={4}>
        <AutoComplete
          name="maritalPartnerCityId"
          label="Cidade*"
          options={citiesOptions}
          value={
            formData?.['maritalPartnerCityId']
              ? {
                  id: formData['maritalPartnerCityId'],
                  value: formData['maritalPartnerCityId'],
                  label: citiesOptions.find(
                    e => e.value === formData['maritalPartnerCityId']
                  )?.label,
                  name: citiesOptions.find(
                    e => e.value === formData['maritalPartnerCityId']
                  )?.label,
                }
              : null
          }
          onChange={e =>
            onChange({
              target: { value: e?.value, name: 'maritalPartnerCityId' },
            })
          }
          disabled={disableAllInputs}
          minHeight="58px"
        />
      </Grid>
    </Grid>
  );
};

export default MaritalForm;
